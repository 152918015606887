(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.list.controller:EventsListCtrl
   *
   * @description
   *
   */
  angular
    .module('events.list')
    .controller('EventsListCtrl', EventsListCtrl);

  function EventsListCtrl(Restangular) {
    var vm = this;

    vm.events = [];

    Restangular.all('events').getList().then(function (events) {
      vm.events = events;
    });
  }
}());
